<template>
    <VApp>
        <h1 class="cs-header">Finden Sie Handwerker in Ihrer Nähe</h1>
        <p>
            Sie möchten die Montage unserer Systeme nicht selbst vornehmen und suchen einen Handwerker in Ihrer
            Nähe?<br/>
            Über unsere Handwerkersuche finden Sie bei uns registrierte Handwerker für eine fachgerechte Ausführung
            vor Ort.
        </p>
        <VContainer class="cs-outer-container cs-selection-container">
            <VRow class="cs-location-selection-row">
                <VCol class="cs-location-selection-col" cols="12" md="6">
                    <div class="cs-input">
                        <p class="cs-instructions">Geben Sie einfach Ihre Adresse ein um einen bei uns registrierten
                            Handwerker in Ihrer Nähe zu finden.</p>
                        <div class="cs-zip-input">
                            <VAutocompleteRewrite
                                v-model="autoCompleteAddress"
                                :items="searchSuggestions"
                                label="Stadt oder PLZ auswählen"
                                required
                                :return-object="true"
                                append-icon="mdi-crosshairs-gps"
                                :hide-no-data="true"
                                :auto-select-first="true"
                                @click:append="getLocation"
                                @inputChangedByUser="fetchPredictions"
                                :error="inputLocationError"
                            >
                            </VAutocompleteRewrite>
                        </div>
                        <VCheckbox
                            :ripple="false"
                            v-model="agreeToTermsSearch"
                            :error="agreeToTermsSearchError"
                        >
                            <template v-slot:label>
                                <div class="cs-input-label">
                                    Ich bestätige, dass ich die <a href="/de/Informationen/AGB/" target="_blank">AGB »</a>
                                    und die Hinweise
                                    zur <a href="/de/Informationen/Hinweise-zur-Handwerkersuche/" target="_blank">Handwerkersuche
                                    »</a> gelesen habe.
                                </div>
                            </template>
                        </VCheckbox>
                        <button class="cs-button cs-search" @click="updateGoogleMap()">
                            Handwerker finden
                        </button>
                    </div>
                </VCol>
                <VCol class="cs-location-selection-col" xs="12">
                    <div class="cs-map" ref="googleMap"></div>
                </VCol>
            </VRow>
        </VContainer>
        <div v-if="this.craftsManCount">
            <div class="craftsman-result-header">
                <p class="cs-result-header-label">{{ this.craftsManCount }} Handwerker wurden in Ihrer Nähe gefunden -
                    Sortierung nach Entfernung:</p>
                <VCheckbox
                    :ripple="false"
                    v-model="checkAll"
                    label="Alle Handwerker kontaktieren"
                    class="cs-result-header-check-all"
                ></VCheckbox>
            </div>
            <VContainer class="cs-outer-container">
                <VRow>
                    <VCol
                        v-for="(craftsMan, index) in this.craftsMen"
                        v-bind:key="index"
                        cols="12"
                        sm="6"
                        md="4"
                        class="cs-craftsman-col"
                    >
                        <VCard class="cs-craftsman-card">
                            <VCardTitle>
                                {{ craftsMan.customFields.insetto_craftsmen_search_name }}
                            </VCardTitle>
                            <VCardSubtitle>
                                <span v-for="(service, index2) in craftsMan.services" v-bind:key="index2">
                                    <span v-if="index2 != 0">, </span><span>{{ $t(service) }}</span>
                                </span>
                            </VCardSubtitle>
                            <VCardSubtitle>
                                <div class="cs-craftsman-pricing">
                                    <div>
                                        <span v-if="craftsMan.customFields.insetto_craftsmen_search_delivery_expense">
                                            Anfahrtspauschale:
                                            {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(craftsMan.customFields.insetto_craftsmen_search_delivery_expense) }}
                                        </span>
                                    </div>
                                    <div>
                                        <span v-if="craftsMan.customFields.insetto_craftsmen_search_min_contract_value">
                                            Mindestauftragswert:
                                            {{ Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(craftsMan.customFields.insetto_craftsmen_search_min_contract_value) }}
                                        </span>
                                    </div>
                                </div>
                            </VCardSubtitle>
                            <VCardText class="cs-card-text">
                                <div v-if="craftsMan.ratingCount" class="cs-craftsman-stars">
                                    <div class="cs-stars-wrapper">
                                        <VIcon
                                            v-for="(number, index) in [1,2,3,4,5]"
                                            :key="index"
                                            v-bind:class="{ filled: number <= craftsMan.rating }"
                                        >
                                            mdi-star
                                        </VIcon>
                                    </div>
                                    {{ craftsMan.ratingCount }} Bewertungen
                                </div>
                                <div v-else>
                                    Noch keine Bewertungen
                                </div>
                                <div class="cs-craftsman-distance">
                                    ca. {{ craftsMan.distance.toFixed(2) }} km entfernt
                                </div>
                            </VCardText>
                        </VCard>
                        <VCheckbox
                            :ripple="false"
                            v-model="craftsMan.chosen"
                            label="Diesen Handwerker kontaktieren"
                            class="cs-craftsman-check"
                        ></VCheckbox>
                    </VCol>
                </VRow>
            </VContainer>
            <VContainer class="cs-outer-container cs-contact-container">
                <h2 class="cs-contact-header">Handwerker kontaktieren</h2>
                <VRow>
                    <VCol
                        cols="12"
                        md="4"
                    >
                        <p>
                            <br class="cs-linebreak"/>
                            Ihre Kontaktdaten:
                        </p>
                        <VRow>
                            <VCol
                                cols="12"
                                sm="6"
                                md="12"
                            >
                                <VTextField
                                    v-model="customerName"
                                    :error="customerNameError"
                                    label="Name"
                                    autocomplete="name"
                                    solo
                                    class="cs-input-field"
                                ></VTextField>
                                <VTextField
                                    v-model="customerEmail"
                                    :error="customerEmailError"
                                    label="E-Mail-Adresse"
                                    type="email"
                                    autocomplete="email"
                                    solo
                                    class="cs-input-field"
                                ></VTextField>
                                <VTextField
                                    v-model="customerPhone"
                                    label="Telefon mit Vorwahl"
                                    autocomplete="tel"
                                    solo
                                    class="cs-input-field"
                                ></VTextField>
                            </VCol>
                            <VCol
                                cols="12"
                                sm="6"
                                md="12"
                            >
                                <p>Wie möchten Sie kontaktiert werden?</p>
                                <VCheckbox
                                    :ripple="false"
                                    v-model="contactViaEmail"
                                    label="bitte per E-Mail"
                                ></VCheckbox>
                                <div class="cs-phone-wrapper">
                                    <VCheckbox
                                        :ripple="false"
                                        v-model="contactViaPhone"
                                        label="bitte per Telefon"
                                        class="cs-input-phone"
                                    ></VCheckbox>
                                    <VTextField
                                        v-model="contactViaPhoneTime"
                                        label="beste Anrufzeit"
                                        solo
                                        class="cs-phone-time"
                                    ></VTextField>
                                </div>
                            </VCol>
                        </VRow>
                    </VCol>
                    <VCol
                        cols="12"
                        md="4"
                    >
                        <p>
                            Ihre Nachricht an die<br class="cs-linebreak"/>
                            von Ihnen ausgewählten Handwerker:
                        </p>
                        <VTextarea
                            v-model="contactText"
                            :error="contactTextError"
                            class="cs-contact-text-area"
                            solo
                        ></VTextarea>
                    </VCol>
                    <VCol
                        cols="12"
                        md="4"
                    >
                        <VCheckbox
                            :ripple="false"
                            v-model="agreeToTermsContact"
                            :error="agreeToTermsContactError"
                            :label="$t('Herewith I declare')"
                            class="cs-check-terms"
                        ></VCheckbox>
                        <button
                            class="cs-button cs-submit"
                            @click="contactCraftsman()"
                        >
                            Handwerker kontaktieren
                        </button>
                    </VCol>
                </VRow>
            </VContainer>
        </div>
        <div class="cs-info-wrapper">
            <div class="cs-info-wrapper-inner">
                <p class="cs-info-header h2">Sie sind selbst Handwerker?</p>
                <span class="cs-info-text">
                    <b>Sie sind selbst Handwerker und daran interessiert in unserer Handwerkersuche gelistet zu werden?</b><br/>
                    Dann melden Sie sich bei uns unter <a href="mailto:haendler@insetto.eu">haendler@insetto.eu »</a>
                </span>
            </div>
        </div>
        <VDialog
            v-model="showDialog"
            max-width="500"
        >
            <VCard>
                <VCardTitle>{{ dialogTitle }}</VCardTitle>
                <VCardText>{{ dialogText }}</VCardText>
                <VCardActions>
                    <VSpacer></VSpacer>
                    <VBtn text @click="showDialog = false">OK</VBtn>
                </VCardActions>
            </VCard>
        </VDialog>
    </VApp>
</template>
<script>
import {VApp, VCheckbox, VBtn, VRow, VCol, VContainer} from 'vuetify/lib';
import {Loader} from '@googlemaps/js-api-loader';
import VAutocompleteRewrite from "./vuetify-rewrite/VAutocompleteRewrite";
import axios from 'axios';

// prevent Google Maps from embedding a custom version of Roboto which will lead to font reloading for the whole page
// see https://stackoverflow.com/questions/25523806/google-maps-v3-prevent-api-from-loading-roboto-font

var head = document.getElementsByTagName('head')[0];
var insertBefore = head.insertBefore;
head.insertBefore = function (newElement, referenceElement) {
    if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') > -1) {
        return;
    }
    insertBefore.call(head, newElement, referenceElement);
};

export default {
    data: () => ({
        autoCompleteAddress: {},
        autoCompleteAddressSync: '',
        searchSuggestions: [],
        exactLocation: '',
        inputLocationError: false,
        agreeToTermsSearch: false,
        agreeToTermsSearchError: false,
        agreeToContact: false,
        googleApi: {},
        map: {},
        swContextToken: '',
        craftsManCount: 0,
        craftsMen: [],
        customerName: '',
        customerNameError: false,
        customerEmail: '',
        customerEmailError: false,
        customerPhone: '',
        contactText: '',
        contactViaPhoneTime: '',
        contactTextError: false,
        agreeToTermsContact: false,
        agreeToTermsContactError: false,
        showDialog: false,
        dialogTitle: '',
        dialogText: '',
        contactViaEmail: false,
        contactViaPhone: false
    }),
    created() {

        this.contactText = this.$t('Good Day');

        axios.get(`${process.env.VUE_APP_INSETTO_WEBSHOP_URL}/store-api/context`, {
            headers: {
                'sw-access-key': process.env.VUE_APP_INSETTO_WEBSHOP_KEY
            }
        }).then((response) => {
            if (response?.data?.token) {
                this.swContextToken = response.data.token;
            }
        });

        new Loader({
            libraries: ['places'],
            apiKey: 'AIzaSyBnmWg88TBHTLdfXTGl-7QTy92H2wDMUZo',
            language: 'de'
        }).load().then(() => {
            this.googleApi = window.google;
            this.geoCoder = new this.googleApi.maps.Geocoder();
            this.autoCompleteService = new this.googleApi.maps.places.AutocompleteService();

            this.initializeGoogleMap();
        });
    },
    computed: {
        checkAll: {
            get() {
                let value = false;
                if (this.craftsMen?.length) {
                    let allChosen = true;
                    for (let craftsMan of this.craftsMen) {
                        if (!craftsMan.chosen) {
                            allChosen = false;
                        }
                    }

                    if (allChosen) {
                        value = true;
                    }
                }
                return value;
            },
            set(value) {
                if (this.craftsMen?.length) {
                    for (let craftsMan of this.craftsMen) {
                        if (craftsMan.chosen != value) {
                            craftsMan.chosen = value;
                        }
                    }
                }
            }
        }
    },
    methods: {
        fetchPredictions(value) {
            if (value) {
                this.autoCompleteService.getPlacePredictions({
                    input: value,
                    componentRestrictions: {
                        country: ['de', 'at', 'ch']
                    },
                    types: [
                        '(regions)'
                    ]
                }, (predictions) => {
                    if (predictions && predictions?.length) {
                        for (let prediction of predictions) {
                            this.searchSuggestions.push({
                                text: prediction.description,
                                value: prediction.place_id,
                                disabled: false
                            });
                        }
                    }
                });
            } else {
                this.searchSuggestions = [];
                this.autoCompleteAddress.value = null;
            }
        },
        getLocation() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.geoCoder.geocode(
                    {
                        location: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                    },
                    (result) => {
                        if (result && result.length) {
                            this.searchSuggestions = [];

                            let foundLocation = {
                                text: result[0].formatted_address,
                                value: result[0].place_id
                            }
                            this.searchSuggestions.push(foundLocation);
                            this.autoCompleteAddress = foundLocation;
                        }
                    });
            });
        },
        async initializeGoogleMap() {
            this.map = new this.googleApi.maps.Map(this.$refs.googleMap, {
                zoom: 5,
                center: {lat: 51.322619602876884, lng: 10.186782364460036},
                mapTypeControl: false,
                streetViewControl: false,
            })
        },
        async updateGoogleMap() {

            if (!this.agreeToTermsSearch) {
                this.agreeToTermsSearchError = true;
            } else {
                this.agreeToTermsSearchError = false;
            }

            if (!this?.autoCompleteAddress?.value || !this.autoCompleteAddress) {
                this.inputLocationError = true;
            } else {
                this.inputLocationError = false;
            }

            if (this.agreeToTermsSearchError || this.inputLocationError) {
                return;
            }

            this.geoCoder.geocode({
                'placeId': this.autoCompleteAddress.value
            }, async (result) => {
                if (result.length) {
                    let foundCoordinates = {
                        lat: result[0].geometry.location.lat(),
                        lon: result[0].geometry.location.lng()
                    };

                    let craftsMenSearchResult = await axios.post(
                        `${process.env.VUE_APP_INSETTO_WEBSHOP_URL}/store-api/craftsmen/search`,
                        {
                            'data': {
                              'searchAddress': foundCoordinates
                            }
                        },
                        {
                            headers: {
                                'sw-access-key': process.env.VUE_APP_INSETTO_WEBSHOP_KEY,
                                'sw-context-token': this.swContextToken
                            }
                        });

                    if (craftsMenSearchResult?.data?.total) {

                        this.checkAll = false;

                        // load map

                        var selectedLocation = {lat: foundCoordinates.lat, lng: foundCoordinates.lon};

                        this.map = new this.googleApi.maps.Map(this.$refs.googleMap, {
                            zoom: 8,
                            center: selectedLocation,
                            mapTypeControl: false,
                            streetViewControl: false,
                        })

                        this.craftsManCount = craftsMenSearchResult.data.total;
                        this.craftsMen = [];

                        let possibleServices = [
                            'insetto_craftsmen_search_service_consulting',
                            'insetto_craftsmen_search_service_measuring',
                            'insetto_craftsmen_search_service_installation',
                            'insetto_craftsmen_search_service_fullservice'
                        ];

                        for (let craftsMan of craftsMenSearchResult.data.elements) {

                            craftsMan.services = [];
                            craftsMan.chosen = false;

                            for (let possibleService of possibleServices) {
                                if (craftsMan.customFields[possibleService] == "1") {
                                    craftsMan.services.push(possibleService);
                                }
                            }

                            this.craftsMen.push(craftsMan);

                            let craftsManPosition = {
                                lat: craftsMan.customFields.insetto_craftsmen_search_lat,
                                lng: craftsMan.customFields.insetto_craftsmen_search_lon
                            }

                            let marker = new this.googleApi.maps.Marker({
                                position: craftsManPosition,
                                title: craftsMan.customFields.insetto_craftsmen_search_name,
                                map: this.map,
                                animation: this.googleApi.maps.Animation.DROP,
                            });

                            let services = '';

                            for (let service of craftsMan.services) {

                                services += services ? ', ' : '';
                                services += this.$t(service);
                            }

                            let infoWindow = new this.googleApi.maps.InfoWindow({
                                content: `<div>
                                            ${craftsMan.customFields.insetto_craftsmen_search_name}<br/>
                                            Leistungen: ${services}
                                          </div>`
                            });

                            this.googleApi.maps.event.addListener(marker, 'click', function () {
                                infoWindow.open(this.map, marker);
                            });

                        }
                    } else {
                        this.showDialog = true;
                        this.craftsManCount = 0;
                        this.dialogTitle = "Keine Handwerker gefunden";
                        this.dialogText = "Leider wurden in Ihrer näheren Umgebung keine registrierten Handwerker gefunden."
                    }

                }
            });
        },
        async contactCraftsman() {

            let checkFields = [
                {
                    name: 'customerName',
                    error: 'customerNameError'
                },
                {
                    name: 'customerEmail',
                    error: 'customerEmailError'
                },
                {
                    name: 'agreeToTermsContact',
                    error: 'agreeToTermsContactError'
                },
                {
                    name: 'contactText',
                    error: 'contactTextError'
                }
            ];

            let isValid = true;

            for (let checkField of checkFields) {
                if (!this[checkField.name]) {
                    this[checkField.error] = true;
                    isValid = false;
                } else {
                    this[checkField.error] = false;
                }
            }

            const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
            if(!re.test(this['customerEmail'].toLowerCase())) {
                this['customerEmailError'] = true;
                isValid = false;
            }

            if (!isValid) {
                return;
            }

            let chosenCraftsmenIds = [];

            for (let craftsman of this.craftsMen) {
                if (craftsman.chosen) {
                    chosenCraftsmenIds.push(craftsman.id);
                }
            }

            if (!chosenCraftsmenIds.length) {
                this.showDialog = true;
                this.dialogTitle = "Bitte Handwerker auswählen";
                this.dialogText = "Bitte wählen Sie mindestens einen Handwerker aus, den Sie kontaktieren möchten."
                return;
            }

            let craftsMenSearchContactResult = await axios.post(
                `${process.env.VUE_APP_INSETTO_WEBSHOP_URL}/store-api/craftsmen/contact`,
                {
                    'customer_name': this.customerName,
                    'customer_email': this.customerEmail,
                    'customer_phone': this.customerPhone,
                    'message': this.contactText,
                    'chosen_craftsmen': chosenCraftsmenIds,
                    'contact_via_email': this.contactViaEmail,
                    'contact_via_phone': this.contactViaPhone,
                    'contact_via_phone_time': this.contactViaPhoneTime
                },
                {
                    headers: {
                        'sw-access-key': process.env.VUE_APP_INSETTO_WEBSHOP_KEY,
                        'sw-context-token': this.swContextToken
                    }
                }
            );

            if (craftsMenSearchContactResult?.data?.success == true) {
                window.location = '/de/Handwerker-Kontaktiert/';
            }
        }
    },
    components: {
        VApp,
        VCheckbox,
        VBtn,
        VAutocompleteRewrite,
        VContainer,
        VRow,
        VCol
    }
}
</script>

<style>

[data-vuetify] .v-application {
    font-family: Arial;
}

[data-vuetify] .cs-selection-container {
    margin-bottom: 20px;
    margin-top: 20px;
}

[data-vuetify] .cs-contact-container {
    margin-top: 20px;
}

[data-vuetify] .cs-outer-container {
    max-width: none !important;
    padding-left: 0px;
    padding-right: 0px;
}

[data-vuetify] .cs-location-selection-col {
    margin-top: 20px;
    margin-bottom: 20px;
}

[data-vuetify] .cs-location-selection-row {
    align-items: center;
}

[data-vuetify] .cs-map {
    height: 400px; /* The height is 400 pixels */
    width: 100%; /* The width is the width of the web page */
}

[data-vuetify] .cs-zip-input .v-text-field {
    max-width: 400px;
}

[data-vuetify] .craftsman-result-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

[data-vuetify] .cs-result-header-check-all {
    float: right;
    margin-top: 0px;
    padding-top: 0px;
}

[data-vuetify] .cs-result-header-label {
    display: inline;
    float: left;
}

[data-vuetify] .theme--light.v-label.error--text {
    color: red;
}

[data-vuetify] .v-textarea.cs-contact-text-area textarea {
    min-height: 339px;
}

[data-vuetify] .cs-header {
    margin-bottom: 30px;
}

[data-vuetify] .cs-input {
    max-width: 500px;
}

[data-vuetify] .cs-instructions {
    font-weight: bold;
}

[data-vuetify] .v-application--wrap {
    min-height: 0px;
}

[data-vuetify] .cs-button {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: 0.0625rem solid transparent;
    padding: 0.125rem 0.75rem;
    font-size: 0.875rem;
    line-height: 2.125rem;
    border-radius: 0.1875rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #212529;
    background-color: #ffcf61;
    border-color: #ffcf61;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
}

[data-vuetify] .cs-button:hover {
    color: #212529;
    background-color: #ffc33b;
    border-color: #ffc02e;
}

[data-vuetify] .theme--light.v-label {
    color: #000;
}

[data-vuetify] .cs-craftsman-card {
    box-shadow: none !important;
    border: 1px solid black !important;
    border-radius: 0px !important;
    height: 100%;
    padding-bottom: 62px;
}

[data-vuetify] .v-card__title {
    font-weight: bold;
}

[data-vuetify] .theme--light.v-card .v-card__subtitle {
    padding-top: 16px;
    color: #000;
}

[data-vuetify] .cs-craftsman-col {
    position: relative;
    margin-bottom: 35px;
}

[data-vuetify] .cs-craftsman-check {
    position: absolute;
    left: 10px;
    bottom: -45px;
}

[data-vuetify] .cs-contact-header {
    margin-bottom: 30px;
}

[data-vuetify] .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none;
    border: 1px solid #bcc1c7;
    margin-bottom: 15px;
}

[data-vuetify] .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat).error--text > .v-input__control > .v-input__slot {
    border: 1px solid #dc3545;
}

[data-vuetify] .v-text-field.v-text-field--solo .v-label {
    color: #bcc1c7;
}

[data-vuetify] .v-text-field.v-text-field--solo .v-text-field__details {
    display: none;
}

[data-vuetify] .cs-input-phone {
    margin-top: 0px;
}

[data-vuetify] .cs-phone-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

[data-vuetify] .cs-phone-time {
    max-width: 180px;
}

[data-vuetify] .cs-submit {
    font-size: 16px;
    padding: 10px 35px;
    margin-top: 50px;
    border-radius: 8px;
    margin-left: 32px;
}

[data-vuetify] .cs-check-terms {
    margin-top: 55px;
}

[data-vuetify] .cs-check-terms .v-input__slot {
    align-items: flex-start;
}

[data-vuetify] .cs-search {
    font-size: 16px;
    padding: 5px 15px;
}

[data-vuetify] .cs-info-wrapper-inner {
    text-align: center;
    background-color: #f5f5f5;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    margin-top: 60px;
    margin-bottom: 40px;
}

[data-vuetify] .cs-input-label {
    max-width: 366px;
}

[data-vuetify] .cs-card-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 62px;
    position: absolute;
    bottom: 0px;
}

[data-vuetify] .cs-craftsman-stars {
    text-align: center;
    min-width: 125px;
}

[data-vuetify] .cs-craftsman-distance {
    text-align: right;
}

[data-vuetify] .cs-stars-wrapper .filled {
    color: #f7ce61;
}

[data-vuetify] .cs-stars-wrapper {
    display: block;
}

@media (max-width: 1199px) {
    [data-vuetify] .cs-submit {
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;
    }
}

@media (max-width: 959px) {
    [data-vuetify] .cs-input-field {
        max-width: 300px;
    }

    [data-vuetify] .cs-linebreak {
        display: none;
    }

    [data-vuetify] .cs-phone-wrapper {
        justify-content: initial;
    }

    [data-vuetify] .cs-phone-time {
        margin-left: 30px !important;
    }

    [data-vuetify] .cs-check-terms {
        margin-top: 0px;
    }

    [data-vuetify] .cs-submit {
        display: block;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }

    [data-vuetify] .cs-selection-container,
    [data-vuetify] .cs-location-selection-col {
        margin-top: 0px;
    }

    [data-vuetify] .cs-info-wrapper-inner {
        width: 100%;
    }
}

@media (max-width: 467px) {
    [data-vuetify] .cs-info-wrapper-inner {
        padding: 10px;
    }
}

@media (max-width: 339px) {
    [data-vuetify] .cs-submit {
        width: 100%;
        margin-left: auto;
        marign-right: auto;
    }
}

</style>